/* .priceDiscount del {
  text-decoration: none;
  position: relative;
  font-size: 2rem;
  font-weight: 200;
}

.priceDiscount del:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid;
  height: 4px;
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: rotate(-11deg);
}

.priceDiscount del:after {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid;
  height: 4px;
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: rotate(11deg);
}

.priceDiscount ins {
  font-size: 60px;
  font-weight: 500;
  text-decoration: none;
  padding: 1em 1em 1em 0.5em;
} */
.priceDiscount del {
  text-decoration: none;
  position: relative;
  font-size: 1.8rem;
}

.priceDiscount del:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 4px solid;
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  transform: rotate(-7deg);
}

.priceDiscount ins {
  text-decoration: none;
  padding: 1em 1em 1em 0.5em;
  font-size: 3rem;
}
